<template>
  <div>
    <div class="mb-0 image-message" v-if="botMessageInEdit.isSaved">
      <img
        :src="botMessageInEdit.type.fileUrl"
        class="img-message"
        alt="bot-image"
        v-if="botMessageInEdit.type.fileUrl"
      />
    </div>

    <div class="mb-0">
      <Message class="chat__input flex bg-white" :has-errors="hasError" />
    </div>

    <form
      @submit.prevent="addMessage()"
      class="submit-button chat__input flex p-2 bg-white"
    >
      <vs-spacer></vs-spacer>
      <vs-button
        :disabled="buttonDisabled"
        class="bg-primary-gradient ml-4"
        type="filled"
      >
        <span
          v-if="!buttonDisabled && !botMessageInEdit.isSaved"
          v-text="lang.botMaker.botEditor.addMessage[languageSelected]"
        ></span>

        <span
          v-if="!buttonDisabled && botMessageInEdit.isSaved"
          v-text="lang.botMaker.botEditor.modifyMessage[languageSelected]"
        ></span>

        <span
          v-if="buttonDisabled && !botMessageInEdit.isSaved"
          v-text="lang.botMaker.botEditor.addingMessage[languageSelected]"
        ></span>
      </vs-button>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Message: () => import('./quickReplyMessage/Message.vue')
  },
  data() {
    return {
      buttonDisabled: false,
      hasError: false
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', ['botMessageInEdit', 'botMakerMessages', 'botId'])
  },
  methods: {
    ...mapActions('botMaker', ['SAVE_BOT_MESSAGE', 'GET_DIALOGS']),
    async addMessage() {
      this.hasError = this.botMessageInEdit.type.name.length === 0

      if (this.hasError) {
        return
      }

      this.$vs.loading()
      const getDialogsId =
        this.botMakerMessages[0] && this.botMakerMessages[0].dialogSavedId
      const { dialogSavedId } = this.botMessageInEdit

      // on update image
      if (this.botMessageInEdit.isSaved) {
        await this.botMessageInEdit.type.save(this.botId)
      }

      this.SAVE_BOT_MESSAGE(this.botMessageInEdit)
        .then(async response => {
          await this.GET_DIALOGS(dialogSavedId || getDialogsId)
          return response
        })
        .catch(error => {
          return error
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.submit-button {
  text-align: right !important;
}

.image-message {
  display: flex;
  justify-content: center;
  .img-message {
    max-height: 150px;
    border-radius: 5px;
  }
}
</style>
